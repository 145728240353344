import React from 'react';

export default function PayForService() {
  const services = [
    {
      title: 'Essentials LLC Package',
      url: 'https://checkout.square.site/merchant/ML2AE7CFS776E/checkout/DSHYOQ5T3RXS4J4C6QAPGUUS',
    },
    {
      title: 'Complete LLC Package',
      url: 'https://checkout.square.site/merchant/ML2AE7CFS776E/checkout/DSHYOQ5T3RXS4J4C6QAPGUUS',
    },
    {
      title: 'Gold LLC Package',
      url: 'https://checkout.square.site/merchant/ML2AE7CFS776E/checkout/DSHYOQ5T3RXS4J4C6QAPGUUS',
    },
    {
      title: 'Business Credit Pathway (Standard)',
      url: 'https://square.link/u/ZviS2848',
    },
    {
      title: 'Business Credit Pathway (Premier)',
      url: 'https://square.link/u/ArUmnZ8j',
    },
    {
      title: 'Trademark Registration',
      url: 'https://square.link/u/WDihc5CD',
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <h1 className="text-2xl font-bold text-center my-4">Client Services</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
        {services.map((service, index) => (
          <a
            key={index}
            href={service.url}
            className="bg-white rounded-lg shadow-md px-5 py-9 flex flex-col items-center justify-center hover:bg-gray-100 transition relative"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2 className="font-semibold text-lg">{service.title}</h2>
            <div className="absolute right-2 bottom-1">
              <div className="flex flex-row justify-center items-baseline gap-2">
                <p className="text-xs text-gray-600/90 mt-2">Secure Checkout</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 24 24"
                  className="fill-current text-[#34a9d5]"
                >
                  <path d="M16.53 7l-.564 2h-15.127l-.839-2h16.53zm-14.013 6h12.319l.564-2h-13.722l.839 2zm5.983 5c-.828 0-1.5.672-1.5 1.5 0 .829.672 1.5 1.5 1.5s1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm11.305-15l-3.432 12h-13.017l.839 2h13.659l3.474-12h1.929l.743-2h-4.195zm-6.305 15c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5z" />
                </svg>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}
