import { useState } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/20/solid';

type NavbarProps = {};

const Navbar: React.FC<NavbarProps> = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-black text-white w-full p-12">
      <div className="max-w-6xl mx-auto px-4">
        <div className="">
          {/* above hr line */}
          <div className="hidden lg:flex lg:flex-col">
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-2 py-4">
                <a
                  href="mailto:info@fortuneipgroup.com"
                  className="text-white text-lg"
                >
                  info@fortune
                  <span className="fill-current text-[#34a9d5]">ipgroup</span>
                  .com
                </a>

                {/* phone number link */}
                <a href="tel:9735595452">(973) 559-5452</a>
              </div>

              {/* social link container */}
              <div className="flex space-x-4">
                <div>
                  <a
                    href="https://www.facebook.com/FortuneIPG"
                    className="text-white text-xl hover:text-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      className="fill-current text-blue-500"
                    >
                      <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z" />
                    </svg>
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.instagram.com/fortuneipgroup/"
                    className="text-white text-xl hover:text-gray-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      className="fill-current text-fuchsia-500"
                    >
                      <path d="M15.233 5.488c-.843-.038-1.097-.046-3.233-.046s-2.389.008-3.232.046c-2.17.099-3.181 1.127-3.279 3.279-.039.844-.048 1.097-.048 3.233s.009 2.389.047 3.233c.099 2.148 1.106 3.18 3.279 3.279.843.038 1.097.047 3.233.047 2.137 0 2.39-.008 3.233-.046 2.17-.099 3.18-1.129 3.279-3.279.038-.844.046-1.097.046-3.233s-.008-2.389-.046-3.232c-.099-2.153-1.111-3.182-3.279-3.281zm-3.233 10.62c-2.269 0-4.108-1.839-4.108-4.108 0-2.269 1.84-4.108 4.108-4.108s4.108 1.839 4.108 4.108c0 2.269-1.839 4.108-4.108 4.108zm4.271-7.418c-.53 0-.96-.43-.96-.96s.43-.96.96-.96.96.43.96.96-.43.96-.96.96zm-1.604 3.31c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667zm4.333-12h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm.952 15.298c-.132 2.909-1.751 4.521-4.653 4.654-.854.039-1.126.048-3.299.048s-2.444-.009-3.298-.048c-2.908-.133-4.52-1.748-4.654-4.654-.039-.853-.048-1.125-.048-3.298 0-2.172.009-2.445.048-3.298.134-2.908 1.748-4.521 4.654-4.653.854-.04 1.125-.049 3.298-.049s2.445.009 3.299.048c2.908.133 4.523 1.751 4.653 4.653.039.854.048 1.127.048 3.299 0 2.173-.009 2.445-.048 3.298z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <hr />
          </div>

          {/* below hr line */}
          <div className="flex justify-center items-center lg:justify-between h-16 gap-6">
            {/* Website Logo */}
            <a
              href="/"
              className="flex flex-col items-center justify-between gap-"
            >
              <span className="font-semibold text-xl">
                FORTUNE{' '}
                <span className="fill-current text-[#34a9d5]">IP GROUP</span>
              </span>
              <p className="text-sm">Legal Services Company</p>
            </a>
            {/* Mobile menu button */}
            <div className="lg:hidden ml-5">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="text-white z-50"
              >
                {isOpen ? (
                  <XMarkIcon className="h-8 w-8" />
                ) : (
                  <Bars3Icon className="h-8 w-8" />
                )}
              </button>
            </div>

            {/* contact now button */}
            <div className="hidden lg:flex gap-4">
              <a
                href="/booking-page"
                className="py-2 px-4 bg-green-500 text-white rounded-full hover:bg-green-700"
              >
                Contact Us Now!
              </a>
              <a
                href="/pay-for-service"
                className="text-lg text-white bg-green-500 px-4 py-2 rounded-full hover:bg-green-700"
              >
                Pay for Service
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Fullscreen Mobile Menu */}
      <div
        className={`${
          isOpen ? 'flex' : 'hidden'
        }  bg-black w-full h-screen justify-center pt-12`}
      >
        <div className="flex flex-col items-center space-y-6">
          <a href="/" className="text-lg text-white hover:text-gray-300">
            Home
          </a>
          <a href="/" className="text-lg text-white hover:text-gray-300">
            Book Online
          </a>
          {/* <a href="/" className="text-lg text-white hover:text-gray-300">
            Blog
          </a> */}
          <a
            href="/booking-page"
            className="py-2 px-4 bg-green-500 text-white rounded-full hover:bg-green-700"
          >
            Contact Us Now!
          </a>
          <a
            href="/pay-for-service"
            className="text-lg text-white bg-green-500 px-4 py-2 rounded-full hover:bg-green-700"
          >
            Pay for Service
          </a>
          {/* social link container */}
          <div className="flex space-x-4">
            <div>
              <a
                href="https://www.facebook.com/FortuneIPG"
                className="text-white text-xl hover:text-gray-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="fill-current text-blue-500"
                >
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z" />
                </svg>
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/fortuneipgroup/"
                className="text-white text-xl hover:text-gray-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="fill-current text-fuchsia-500"
                >
                  <path d="M15.233 5.488c-.843-.038-1.097-.046-3.233-.046s-2.389.008-3.232.046c-2.17.099-3.181 1.127-3.279 3.279-.039.844-.048 1.097-.048 3.233s.009 2.389.047 3.233c.099 2.148 1.106 3.18 3.279 3.279.843.038 1.097.047 3.233.047 2.137 0 2.39-.008 3.233-.046 2.17-.099 3.18-1.129 3.279-3.279.038-.844.046-1.097.046-3.233s-.008-2.389-.046-3.232c-.099-2.153-1.111-3.182-3.279-3.281zm-3.233 10.62c-2.269 0-4.108-1.839-4.108-4.108 0-2.269 1.84-4.108 4.108-4.108s4.108 1.839 4.108 4.108c0 2.269-1.839 4.108-4.108 4.108zm4.271-7.418c-.53 0-.96-.43-.96-.96s.43-.96.96-.96.96.43.96.96-.43.96-.96.96zm-1.604 3.31c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667zm4.333-12h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm.952 15.298c-.132 2.909-1.751 4.521-4.653 4.654-.854.039-1.126.048-3.299.048s-2.444-.009-3.298-.048c-2.908-.133-4.52-1.748-4.654-4.654-.039-.853-.048-1.125-.048-3.298 0-2.172.009-2.445.048-3.298.134-2.908 1.748-4.521 4.654-4.653.854-.04 1.125-.049 3.298-.049s2.445.009 3.299.048c2.908.133 4.523 1.751 4.653 4.653.039.854.048 1.127.048 3.299 0 2.173-.009 2.445-.048 3.298z" />
                </svg>
              </a>
            </div>
          </div>
          <a
            href="mailto:info@fortuneipgroup.com"
            className="text-white text-lg"
          >
            info@fortune
            <span className="fill-current text-[#34a9d5]">ipgroup</span>.com
          </a>
          <a href="tel:9735595452" className="text-white text-lg">
            (973) 559-5452
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
