import React from 'react';
import { CardProps } from '../Interfaces';
import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const BookingCard: React.FC<CardProps> = ({
  imageUrl,
  title,
  description,
  time,
  consultationText,
  onBookNow,
}) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const variants = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0.92 },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
      className="max-w-sm rounded overflow-hidden shadow-lg"
    >
      <img className="w-full" src={imageUrl} alt={title} />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{title}</div>
        <p className="text-gray-700 text-base">{description}</p>
      </div>
      <div className="px-4">
        <hr />
      </div>
      <div className="px-6 pt-4 pb-2">
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
          {time}
        </span>
        {consultationText && (
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mb-2">
            {consultationText}
          </span>
        )}
      </div>
      <div className="px-6 pt-4 pb-2">
        <button
          onClick={onBookNow}
          className="bg-[#34a9d5] hover:bg-[#2986a8] text-white font-bold py-2 px-4 rounded"
        >
          Book Now
        </button>
      </div>
    </motion.div>
  );
};

export default BookingCard;
