import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const WhatWeOffer = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5, // Trigger when 50% of the element is in view
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const variants = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 0.5 } },
    hidden: { opacity: 0, translateY: 20 },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
      className="flex flex-col gap-6 py-3 px-3 pb-10 lg:max-w-[80%] xl:max-w-[70%]"
    >
      <h3 className="text-3xl">What We Offer</h3>
      <div className="text-xl flex flex-col gap-6">
        <p>
          Fortune <span className="fill-current text-[#34a9d5]">IP Group</span>{' '}
          offers inventors, engineers, musicians, and small businesses the
          opportunity to ensure all of their intellectual property has legal
          protection. This is done by first determining the best type of
          protection for a business/invention (patent, trademark, or copyright).
          Next, the IP is protected by registering it with the designated
          federal agency.
        </p>
        <p>
          After the IP has legal protection we find and target the areas of a
          business/industry where profits can be made through licensing and
          selling of the registered intellectual property. Fortune{' '}
          <span className="fill-current text-[#34a9d5]">IP Group</span> offers
          these services together or can assist clients with a single portion of
          the process.
        </p>
      </div>
    </motion.div>
  );
};

export default WhatWeOffer;
