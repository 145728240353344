import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { Routes, Route } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Home from './pages/Home';
import BookingPage from './pages/BookingPage';
import PayForService from './pages/PayForService';

function App() {
  return (
    <div className="flex flex-col items-center gap-8">
      <Navbar />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/booking-page" element={<BookingPage />} />
          <Route path="/pay-for-service" element={<PayForService />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
