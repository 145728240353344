import React from 'react';
import CustomForm from '../components/CustomForm';

export default function BookingPage() {
  return (
    <div>
      <CustomForm apiUrl="https://api.example.com" />
    </div>
  );
}
